
  /**
   * this file was autogenerated by the icon-renderer module
   * the settings for this are in the nuxt.config.ts file
   * if you would like to override the contents in this file you can create a custom component in the
   * assets/icons directory and name it the same as the icon you would like to override
   * */
  <template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.99999 0.666656C4.41666 0.666656 0.666656 4.41666 0.666656 8.99999C0.666656 13.5833 4.41666 17.3333 8.99999 17.3333C13.5833 17.3333 17.3333 13.5833 17.3333 8.99999C17.3333 4.41666 13.5833 0.666656 8.99999 0.666656ZM12.5 7.58332L8.49999 11.5833C8.16666 11.9167 7.66666 11.9167 7.33332 11.5833L5.49999 9.74999C5.16666 9.41666 5.16666 8.91666 5.49999 8.58332C5.83332 8.24999 6.33332 8.24999 6.66666 8.58332L7.91666 9.83332L11.3333 6.41666C11.6667 6.08332 12.1667 6.08332 12.5 6.41666C12.8333 6.74999 12.8333 7.24999 12.5 7.58332Z" fill="#04724D"/>
</svg>

  </template>

  <script>
  export default {
    name: 'IconApprove',
    inheritAttrs: true,
  }
  </script>
